import React from 'react'
import Hero from '../../components/Hero'
import Layout from '../../components/layout'
import { Img } from '@tueri/react'
import Helmet from 'react-helmet'

const meta = {
  title: 'Image optimization',
  image: '274877906961',
  path: '/features/image-optimization/',
  description: 'Automatically compressed images delivered in the best possible format',
  keywords: 'image optimization, image compression, image format, webp images'
}

const ImageOptimization = ({ data }) => (
    <Layout blog>
          <Helmet
            html={[
              { lang: 'en', prefix: 'og: http://ogp.me/ns#' }
            ]}
            meta={[
              { property: 'og:title', content: 'Tueri - ' + meta.title },
              { property: 'twitter:title', content: 'Tueri - ' + meta.title },
              { property: 'og:type', content: 'website' },
              { property: 'og:image', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'twitter:image:src', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'og:url', content: 'https://tueri.io' + meta.path },
              { property: 'og:description', content: meta.description },
              { property: 'twitter:description', content: meta.description },
              { name: 'description', content: meta.description },
              { name: 'keywords', content: meta.keywords },
              { name: 'author', content: 'Dane Stevens' },
              { property: 'twitter:card', content: 'summary_large_image' }
            ]}
          >
            <title>Tueri - { meta.title }</title>
          </Helmet>

        <Hero
            large
            title={ meta.title }
            subTitle={ meta.description }
        />

        <div style={{
            boxShadow: '0 6px 24px rgba(0, 0, 0, 0.4)',
            borderRadius: '4px',
            overflow: 'hidden',
            marginBottom: '10rem'
        }}>
       	  <Img src={ meta.image } alt='Image Optimization' />
        </div>

		<p>
         	Images regularily account for the majority of downloaded bandwidth on a web page. Optimized images have the potential to return some of the largest performance improvements for your website.
       	</p>

		<h2>What is image optimization?</h2>
       	<p>
         	Image optimization is the process of delivering the highest-quality image at the lowest possible file size.
		</p>
		<p>
			There are a number of ways we can accomplish this: image resizing, image compression, converting image formats and image caching.
       	</p>
		<h2>How we accomplish this</h2>
		<p>
			Image optimization is both art and science. We use a number of techniques to help us decide the best way to deliver your images.
		</p>

		<h3>
			Image resizing
		</h3>
		<p>
			We automatically detect the images on your site and measure the dimensions of the parent container holding your image. If you site is responsive, these dimensions will be different depending on the individual device your user is viewing your site from. Each image is then resized on our rendering cluster and delivered to your user's screen in the exact dimensions needed for their particular device.
		</p>

		<h3>Image compression</h3>
		<p>
			Unnecessary bytes and meta information are removed from your images and individual pixels are compressed or removed to save space.
		</p>

		<h3>Image format</h3>
		<p>
			Modern browsers support newer image formats like <a target="_blank" rel="noopener noreferrer" href="https://developers.google.com/speed/webp/">WebP</a>. WebP lossless images are <strong>26% smaller</strong> than PNGs and <strong>25-34% smaller</strong> than JPEGs. We automatically detect browser support and convert your image to WebP if your user's browser can support it.
		</p>

		<h3>Image caching</h3>
		<p>
			Your images are delivered through our CDN (content delivery network) that caches transformed images at the network edge. This image cache allows for insanely fast delivery on subsequent requests for the same image.
		</p>

    </Layout>
)

export default ImageOptimization